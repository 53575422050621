<template>
  <a
    :href="`https://www.instagram.com/numbersgameuk/`"
    target="_blank"
    class="instagram-media w-1/2 lg:w-1/4"
  >
    <div class="pt-[100%] relative">
      <img
        :src="`/instagram-posts/${postImage}`"
        class="w-full h-full object-cover absolute inset-0"
      />
    </div>
  </a>
</template>

<script>
export default {
  name: "Loader",
  props: ["postID", "postImage"],
};
</script>

<style></style>
